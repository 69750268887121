import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants';

const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

const fetchBackendData = async ({ url, errorHandler }) => {
  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else if (response.status === 401) {
    errorHandler({
      variant: 'destructive',
      title: 'Vous semblez être déconnecté. Veuillez vous re-authentifier.',
    });
    return null;
  } else if (response.status === 403) {
    errorHandler({
      variant: 'destructive',
      title: "Vous n'avez pas les permissions pour effectuer cette action.",
    });
    return null;
  } else if (response.status === 404) {
    errorHandler({
      variant: 'destructive',
      title: 'La resource que vous recherchez est introuvable.',
    });
    return null;
  } else {
    errorHandler({
      variant: 'destructive',
      title: 'Une erreur est survenue, veuillez réessayer plus tard.',
    });
    return null;
  }
};

export { fetchBackendData, cn };
