import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarSeparator,
  SidebarMenuItem,
} from '@/components/ui/sidebar';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { NavLink } from 'react-router-dom';
import { ContactRound, ListChecks, ShoppingBasket, PackageOpen, Pill, Receipt, LogOut, Wrench, UserRoundCog, ChevronUp } from 'lucide-react';
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants';

const menuItems = [
  {
    title: 'Panier',
    url: '/carts',
    icon: ShoppingBasket,
  },
  {
    title: 'Medicaments',
    url: '/medications',
    icon: Pill,
  },
  {
    title: 'Bons de livraison',
    url: '/delivery-notes',
    icon: PackageOpen,
  },
  {
    title: 'Inventaire',
    url: '/inventories',
    icon: ListChecks,
  },
  {
    title: 'Patients',
    url: '/customers',
    icon: ContactRound,
  },
];

const signOutFunction = () => {
  localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  window.location.href = '/login';
};

const footerMenuItems = [
  {
    title: 'Account',
    icon: Wrench,
    click: () => {
      console.log('account');
    },
  },
  {
    title: 'Billing',
    icon: Receipt,
    click: () => {
      throw new Error('Billing error !');
    },
  },
  { title: 'Se déconnecter', icon: LogOut, click: signOutFunction },
];

const SidebarFooterDropdown = ({ userName }) => (
  <SidebarMenu>
    <SidebarMenuItem className="ml-2 py-2 justify-center items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <SidebarMenuButton variant="outline">
            <UserRoundCog /> Bonjour, {userName}
            <ChevronUp className="ml-auto" />
          </SidebarMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="top" className="min-w-[--radix-popper-anchor-width] bg-background text-sidebar-foreground">
          {footerMenuItems.map((item) => (
            <DropdownMenuItem key={item.title} className="hover:!bg-sidebar/60" onClick={item.click}>
              <item.icon size={15} />
              <span>{item.title}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </SidebarMenuItem>
  </SidebarMenu>
);

const AppSidebar = ({ userName }) => {
  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <span className="w-full text-center text-2xl font-semibold">
          L<span className="group-data-[collapsible=icon]:hidden">ivy</span>
        </span>
      </SidebarHeader>
      <SidebarSeparator />
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item) => (
                <SidebarMenuItem className="ml-2 py-2 justify-center items-center" key={item.title}>
                  <NavLink to={item.url}>
                    {({ isActive }) => (
                      <SidebarMenuButton
                        isActive={isActive}
                        size="lg"
                        className="text-xl [&>svg]:size-6 group-data-[collapsible=icon]:justify-center"
                        asChild
                      >
                        <span>
                          <item.icon />
                          <span className="group-data-[collapsible=icon]:hidden">{item.title}</span>
                        </span>
                      </SidebarMenuButton>
                    )}
                  </NavLink>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarFooterDropdown userName={userName} />
      </SidebarFooter>
    </Sidebar>
  );
};

export { AppSidebar };
