import './App.css';
import { jwtDecode } from 'jwt-decode';
import { AUTH_TOKEN_STORAGE_KEY } from '@/constants';
import { Layout } from '@/components/layout';
import { ThemeProvider } from '@/components/theme-provider';

import { createRoutesFromElements, createBrowserRouter, RouterProvider, Route, Navigate } from 'react-router-dom';

const App = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const tokenClaims = authToken === null ? null : jwtDecode(authToken);
  const routes = createRoutesFromElements(
    <>
      <Route path="/login" lazy={() => import('@/pages/login')} />
      <Route path="/" element={authToken === null ? <Navigate to="/login" /> : <Layout tokenClaims={tokenClaims} />}>
        <Route index element={<Navigate to="/carts" />} />
        <Route path="/carts" lazy={() => import('@/pages/work-in-progress')} />
        <Route path="/medications" lazy={() => import('@/pages/medications')} />
        <Route path="/delivery-notes" lazy={() => import('@/pages/work-in-progress')} />
        <Route path="/inventories" lazy={() => import('@/pages/work-in-progress')} />
        <Route path="/customers" lazy={() => import('@/pages/work-in-progress')} />
      </Route>
    </>
  );
  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider defaultTheme="light">
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
