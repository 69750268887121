import { AppSidebar } from '@/components/app-sidebar';
import { SidebarProvider, SidebarTrigger } from '@/components/ui/sidebar';
import { Outlet } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { Sun, Moon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/components/theme-provider';

const Layout = ({ tokenClaims }) => {
  const { theme, setTheme } = useTheme();
  return (
    <>
      <SidebarProvider>
        <AppSidebar userName={tokenClaims.firstName} />
        <main className="w-full p-2 flex flex-col">
          <div className="flex gap-2">
            <SidebarTrigger />
            <Button variant="outline" size="icon" className="h-7 w-7" onClick={() => (theme === 'light' ? setTheme('dark') : setTheme('light'))}>
              <Sun className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <Moon className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            </Button>
          </div>
          <div className="px-2 py-4 w-full h-full">
            <Outlet />
          </div>
        </main>
      </SidebarProvider>
      <Toaster />
    </>
  );
};

export { Layout };
