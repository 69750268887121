const AUTH_TOKEN_STORAGE_KEY = 'auth-token';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;
const SEARCH_TIMEOUT_IN_MS = 1000;
const RESULTS_PER_PAGE = 25;

const URLs = {
  login: {
    auth: `${BASE_API_URL}/auth/login/`,
  },
  medications: {
    scan: `${BASE_API_URL}/api/medications/actions/scan/:barCode/`,
    search: `${BASE_API_URL}/api/medications/actions/search/?:queryParams`,
  },
};

export { AUTH_TOKEN_STORAGE_KEY, RESULTS_PER_PAGE, SEARCH_TIMEOUT_IN_MS, SENTRY_DSN, URLs };
